import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const ImageCarousel = ({ slideShowImages }) => {
  return (
    <div className='card mt-4 card-bg-fill'>
      <Carousel data-bs-theme='dark'>
        {slideShowImages.map((slide) => (
          <Carousel.Item key={slide.id} interval={2000}>
            <img
              className='d-block w-100'
              src={slide.image}
              alt={slide.title}
              style={{ height: '450px' }}
            />
            <Carousel.Caption className='carousel-caption-background'>
              <p style={{ fontWeight: 700 }}>{slide.title}</p>
              <p>{slide.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
