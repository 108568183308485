import React, { useEffect, useState, useReducer } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { Bars } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import Form from "../../reusable/form/form";
import Joi from "joi-browser";

import Input from "../../reusable/form/Input";

import { toast } from "react-toastify";
import TextInputLabel from "../../reusable/form/textinputlabel";
import CheckboxInput from "../../reusable/form/checkboxInput";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import DatePicker from "react-datepicker";
import { fetchPropertyAdvertById } from "../../../services/property/advertisement/propertyAdvertisement";
import { createTenantPreScreening } from "../../../services/pre-screening/prescreeningManagementService";
import { formatCurrency } from "../../../services/utilis/currencyUtils";
import { getTenantJobCategories } from "../../../services/systemDefined/tenantJobCategoriesManagement";
import { set } from "lodash";
const { v4: uuidv4 } = require("uuid");

//each adult must sign up and fill out the information, each will be given a invite code which they will use to link their applications to be a part of the same pool of co-occupants, when adult added it will send the
//an email (when the user clicks send invite) to the other applicant, letting them sign up as a co-occupant
const TenantSignUpManagement = ({ isDarkMode }) => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const { campaignId } = useParams();

  const navigate = useNavigate();
  const [data, setData] = useState({
    campaign_id: campaignId,
    email_address: "",
    //password: "",
    first_name: "",
    last_name: "",
    duration_of_current_residence: 0,
    total_monthly_income: 0,
    sources_of_income: [
      {
        job_category_id: "",
        created_at: "",
        updated_at: "",
        is_active: false,
        job_category: "",
      },
    ],
    number_of_occupants: 0,
    reason_for_leaving: "",
    desired_move_in_date: new Date(),
    does_any_one_smoke: false,
    has_pets: false,
    describe_pets: "",
  });

  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advert_id: "",
    landlord_id: "",
    property_unit_id: "",
    date_advert_ends: "",
    campaign_id: "",
    property_unit: {
      property_unit_id: "",
      property_id: "",
      market_rent_amount: 0,
      number_of_bathrooms: 0,
      number_of_bedrooms: 0,
      square_footage: 0,
      unit_name: "",
      //new
      number_of_half_bathrooms: "",
      number_of_rooms: "",
    },
    security_deposit: 0,
    screening_pack_id: "",
    currency_id: "",
    currency_name: "",
    is_advert_expired: false,
    rental_unit_price: 0,
    rental_unit_amt_currency: "",
    unit_advert_image_urls: [],
  });

  const [rentalUnitPrice, setRentalUnitPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const schema = {
    campaign_id: Joi.string().required().label("Campaign ID"),
    email_address: Joi.string().email().required().label("Email Address"),
    //password: Joi.string().min(7).required().label("Password"),
    first_name: Joi.string().min(2).required().label("First Name"),
    last_name: Joi.string().min(2).required().label("Last Name"),
    duration_of_current_residence: Joi.number()
      .min(0)
      .required()
      .label("Duration of Current Residence"),
    total_monthly_income: Joi.number()
      .greater(rentalUnitPrice)
      .required()
      .label("Total Monthly Income")
      .error((errors) => {
        errors.forEach((err) => {
          if (err.type === "number.greater") {
            err.message = `Total Monthly Income must be greater than ${formatCurrency(
              rentalUnitPrice
            )}`;
          }
        });
        return errors;
      }),
    sources_of_income: Joi.array().min(1).required().label("Source of Income"),
    number_of_occupants: Joi.number()
      .min(0)
      .required()
      .label("Number of Occupants"),
    reason_for_leaving: Joi.string()
      .min(2)
      .required()
      .label("Reason for Leaving"),
    desired_move_in_date: Joi.date().required().label("Desired Move In Date"),
    does_any_one_smoke: Joi.boolean().required().label("Does Anyone Smoke?"),
    has_pets: Joi.boolean().required().label("Does Anyone Have Pets?"),
    //describe is only required if hasPets is true
    describe_pets: Joi.when("has_pets", {
      is: true,
      then: Joi.string().min(2).required().label("Describe Pets"),
      otherwise: Joi.string().allow("").optional().label("Describe Pets"),
    }),
  };

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  const [selectedJobCategories, setSelectedJobCategories] = useState([
    {
      job_category_id: "",
      created_at: "",
      updated_at: "",
      is_active: false,
      job_category: "",
    },
  ]);
  const [jobCategories, setJobCategories] = useState([
    {
      job_category_id: "",
      job_category: "",
    },
  ]);
  const [jobCategoryOptions, setJobCategoryOptions] = useState([
    { value: 0, label: "Select Job Category", data: {} },
  ]);

  const fetchTenantJobCategories = async () => {
    //get the job categories
    const jobCategories = await getTenantJobCategories();

    if (jobCategories) {
      console.log("Job Categories");
      console.log(jobCategories);
      setJobCategories(jobCategories);

      //clear the selected job category
      setSelectedJobCategories(null);

      //set the options
      const options = jobCategories.map((jobCategory) => ({
        value: jobCategory.job_category_id,
        label: jobCategory.job_category,
        data: jobCategory,
      }));

      setJobCategoryOptions(options);
    }
  };

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    //load data
    getAdvertDetail();
    fetchTenantJobCategories();
    //after loading
    setLoading({ isLoading: false });
  }, []);

  useEffect(() => {}, [rentalUnitPrice]);

  const getAdvertDetail = async () => {
    //get the advert details
    const advertDetail = await fetchPropertyAdvertById(campaignId);

    if (advertDetail) {
      console.log(advertDetail);
      setPropertyUnitAdvert(advertDetail);
      setRentalUnitPrice(advertDetail.rental_unit_price);
    }
  };

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    //console.log(error);

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var email_address = this.email_address.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    // console.log(errors);
    //show the toasts for the errors
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        toast.error(element);
      }
    }

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    //console.log(data.desiredMoveInDate);

    doSubmit();
  };

  function handleChange(event) {
    let name, value, type, checked;

    if (event && event.target) {
      // Regular input change
      ({ value, type, checked, name } = event.target);
      name = name || event.target.id;
    } else {
      // Date picker change
      name = "desired_move_in_date";
      value = event; // The event is the date object itself
      type = "date";
    }

    let errorMessage = "";
    let newData = { ...data };

    if (type === "checkbox") {
      newData[name] = checked;
    } else if (type === "date") {
      newData[name] = new Date(value).toISOString();
    } else {
      errorMessage = validateProperty({ name, value });
      newData[name] = value;
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: errorMessage,
    }));

    setData(newData);
  }

  const handleJobCategoryChange = (selectedOptions) => {
    if (!selectedOptions) {
      console.log("No Job Category Selected");
      setSelectedJobCategories([]);
      setData((prevData) => ({ ...prevData, sources_of_income: [] }));
      return;
    }
    console.log("Selected Job Category");
    console.log(selectedOptions);
    setSelectedJobCategories(selectedOptions);
    const sourcesOfIncome = selectedOptions.map((option) => ({
      job_category_id: option.value,
      job_category: option.label,
    }));
    setData((prevData) => ({
      ...prevData,
      sources_of_income: sourcesOfIncome,
    }));
  };

  const doSubmit = async () => {
    //login function
    try {
      //const { data } = this.state;
      //FORMAT TO MATCH THE DETAILS NEEDED FROM THE END POINT
      console.log(data);

      console.log(`Unit ID: ${propertyUnitAdvert.property_unit_id}`);

      // Create the tenant profile object that matches TenantModel
      const tenantProfile = {
        tenant_id: uuidv4(),
        first_name: data.first_name,
        middle_name: null, // or "" if the server prefers empty string over null
        last_name: data.last_name,
        email_address: data.email_address,
        //password: "",
        login_code: null,
        expires_at: new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000
        ).toISOString(),
        stripe_customer_id: null,
        phone_number: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        is_deleted: false,
        is_active: true,
        is_verified: false,
        campaign_id: campaignId,
        profile_img_url: "",
        prescreening_info: {
          duration_of_current_residence: parseInt(
            data.duration_of_current_residence
          ),
          total_monthly_income: parseFloat(data.total_monthly_income),
          sources_of_income: data.sources_of_income.map((source) => ({
            job_category_id: source.job_category_id,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            job_category: source.job_category,
          })),
          number_of_occupants: parseInt(data.number_of_occupants),
          reason_for_leaving: data.reason_for_leaving,
          desired_move_in_date: new Date(
            data.desired_move_in_date
          ).toISOString(),
          does_any_one_smoke: data.does_any_one_smoke,
          has_pets: data.has_pets,
          describe_pets: data.has_pets ? data.describe_pets : undefined,
        },
      };

      console.log(tenantProfile);

      // Use this tenantProfile object to send to the server
      const { data: response } = await createTenantPreScreening(tenantProfile);

      console.log("Response");
      console.log(response);

      if (response.is_error) {
        if (response.server_message === "Tenant already exists") {
          toast.error(
            "This email already exists. Please use a different email address."
          );
        } else {
          toast.error(
            response.server_message || "Application Submission Failed"
          );
        }
      } else {
        toast.success(
          "Application Submitted Successfully, check your email for the login code."
        );
        //set it in local storage
        localStorage.setItem("email", data.email_address);
        navigate(`/${"email"}`); //login screen via email
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.email_address = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id="global-loader"
          style={{
            zIndex: "99",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "absolute",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}
      {!campaignId ? (
        <div
          className="page-content"
          data-bs-theme={isDarkMode ? "dark" : "light"}
          style={{ paddingBottom: "10%" }}
        >
          <div class="row">
            <div class="col-xl-8" id="card-slide">
              <div className="alert alert-warning" role="alert">
                <h4 className="alert-heading">Missing Campaign ID</h4>
                <p>
                  A Campaign ID is required in the URL to present an active
                  property unit for rent. Please ensure a valid Campaign ID is
                  provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Rental Application Pre-Screening Form
                      </h4>
                    </div>
                    {/* end card header */}
                    <form onSubmit={handleSubmit} className="card-body">
                      <div className="live-preview">
                        <div className="row gy-4">
                          {/*TextField start*/}

                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="first_name"
                                value={data.first_name}
                                label="First Name"
                                error={errors.first_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*TextField end*/}
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="last_name"
                                value={data.last_name}
                                label="Last Name"
                                error={errors.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="email_address"
                                value={data.email_address}
                                label="Email Address"
                                error={errors.email_address}
                                onChange={handleChange}
                                type={"email"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          {/*
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="password"
                                value={data.password}
                                label="Password"
                                error={errors.password}
                                onChange={handleChange}
                                type={"password"}
                              />
                            </div>
                          </div>
                          */}
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="duration_of_current_residence"
                                value={data.duration_of_current_residence}
                                label="Duration of Current Residence (in months)"
                                error={errors.duration_of_current_residence}
                                onChange={handleChange}
                                type={"number"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="total_monthly_income"
                                value={data.total_monthly_income}
                                label="Total Household Monthly Income"
                                error={errors.total_monthly_income}
                                onChange={handleChange}
                                type={"number"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              {/*} <TextInputLabel
                                name='sourcesOfIncome'
                                value={data.sourcesOfIncome}
                                label='Source of Income'
                                error={errors.sourcesOfIncome}
                                onChange={handleChange}
                                type={'text'}
                              />*/}
                              <label className="form-label">
                                What is your primary Job? (Source of Income)
                              </label>
                              <Select
                                name="sources_of_income"
                                options={jobCategoryOptions}
                                onChange={handleJobCategoryChange}
                                isClearable
                                isMulti={true}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="number_of_occupants"
                                value={data.number_of_occupants}
                                label="Total Number of Occupants (Including Yourself)"
                                error={errors.number_of_occupants}
                                onChange={handleChange}
                                type={"number"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <TextInputLabel
                                name="reason_for_leaving"
                                value={data.reason_for_leaving}
                                label="Reason for Leaving"
                                error={errors.reason_for_leaving}
                                onChange={handleChange}
                                type={"textarea"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <Input
                                showIcon
                                name="desired_move_in_date"
                                selected={data.desired_move_in_date}
                                label="Desired Move In Date"
                                error={errors.desired_move_in_date}
                                onChange={handleChange}
                                type={"date"}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <CheckboxInput
                                name="does_any_one_smoke"
                                label="Does Any of the Occupants Smoke?"
                                checked={data.does_any_one_smoke}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <CheckboxInput
                                name="has_pets"
                                label="Do you have any pets?"
                                checked={data.has_pets}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          {data.has_pets && (
                            <div className="col-xxl-3 col-md-6">
                              <div>
                                <TextInputLabel
                                  name="describe_pets"
                                  value={data.describe_pets}
                                  label="Please Describe Your Pets"
                                  error={errors.describe_pets}
                                  onChange={handleChange}
                                  type={"textarea"}
                                />
                              </div>
                            </div>
                          )}
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                        {/*Register Button*/}
                        <div className="row gy-4" style={{ paddingTop: "2%" }}>
                          <div className="col-lg-12">
                            <div className="d-grid">
                              <button
                                className="btn btn-success fw-bold"
                                disabled={validate()}
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TenantSignUpManagement;
