export const formatDateValue = (dateValue) => {
  if (!dateValue) return "";
  if (typeof dateValue === "string") {
    return dateValue.split("T")[0];
  }
  if (dateValue instanceof Date) {
    return dateValue.toISOString().split("T")[0];
  }
  return "";
};
