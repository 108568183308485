import React, { createContext, useState, useContext } from "react";

const TenantProfileContext = createContext();

export const useTenantProfileContext = () => {
  return useContext(TenantProfileContext);
};

export const TenantProfileProvider = ({ children }) => {
  const [tenantProfile, setTenantProfile] = useState({
    tenant_id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    stripe_customer_id: "",
    phone_number: "",
    campaign_id: "",
    profile_image_url: "",
    pre_screening_detail: {
      duration_of_current_residence: "",
      total_monthly_income: "",
      sources_of_income: [
        {
          job_category_id: "",
          job_category: "",
          created_at: Date(),
          updated_at: Date(),
        },
      ],
      number_of_occupants: "",
      reason_for_leaving: "",
      desired_move_in_date: Date(),
      does_any_one_smoke: false,
      has_pets: false,
      describe_pets: "",
    },
  });

  return (
    <TenantProfileContext.Provider value={{ tenantProfile, setTenantProfile }}>
      {children}
    </TenantProfileContext.Provider>
  );
};
