import React from "react";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import Input from "../../../reusable/form/Input";

const PreviousAddressSection = ({
  previousAddressDetail = {},
  setPreviousAddressDetail,
}) => {
  const handleChange = (name, value) => {
    const newValue = value && value.target ? value.target.value : value;
    setPreviousAddressDetail({
      ...previousAddressDetail,
      [name]: newValue,
    });
  };

  // Updated ensureValue function
  const ensureValue = (value) => {
    return value === undefined || value === null ? "" : value;
  };

  const formatDateValue = (dateValue) => {
    if (!dateValue) return "";
    if (typeof dateValue === "string") {
      return dateValue.split("T")[0];
    }
    if (dateValue instanceof Date) {
      return dateValue.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <div className="row gy-4">
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="address"
          value={ensureValue(previousAddressDetail?.address)}
          label="Street Address"
          onChange={(e) => handleChange("address", e.target.value)}
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="city"
          value={ensureValue(previousAddressDetail?.city)}
          label="City"
          onChange={(e) => handleChange("city", e.target.value)}
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="state"
          value={ensureValue(previousAddressDetail?.state)}
          label="State"
          onChange={(e) => handleChange("state", e.target.value)}
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="zip_code"
          value={ensureValue(previousAddressDetail?.zip_code)}
          label="Zip Code"
          onChange={(e) => handleChange("zip_code", e.target.value)}
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <Input
          showIcon
          name="date_of_residence_start"
          value={formatDateValue(
            previousAddressDetail?.date_of_residence_start
          )}
          label="Date In"
          onChange={(date) => handleChange("date_of_residence_start", date)}
          type="date"
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <Input
          showIcon
          name="date_of_residence_end"
          value={formatDateValue(previousAddressDetail?.date_of_residence_end)}
          label="Date Out"
          onChange={(date) => handleChange("date_of_residence_end", date)}
          type="date"
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="landlord_name"
          value={ensureValue(previousAddressDetail?.landlord_name)}
          label="Landlord Name"
          onChange={(e) => handleChange("landlord_name", e.target.value)}
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="landlord_phone_number"
          value={ensureValue(previousAddressDetail?.landlord_phone_number)}
          label="Landlord Phone"
          onChange={(e) =>
            handleChange("landlord_phone_number", e.target.value)
          }
        />
      </div>
      <div className="col-xxl-3 col-md-6">
        <TextInputLabel
          name="monthly_rent"
          value={ensureValue(previousAddressDetail?.monthly_rent)}
          label="Monthly Rent"
          onChange={(e) => handleChange("monthly_rent", e.target.value)}
          type="number"
        />
      </div>
    </div>
  );
};

export default PreviousAddressSection;
