import http from "../general/httpService";
import config from "../../constants/config.json";
import { Tenant } from "./model/user";

//declaring the constants
const tokenKey = config.access_token;
const refreshToken = config.refresh_token;
const email = config.email;
const userKey = config.tenant_id;
const firstName = config.first_name;
const lastName = config.last_name;
const loginCode = config.login_code;
const profileImg = config.profile_img;
const stripeCustomerId = config.stripe_customer_id;
const isPaid = config.is_paid;

http.setJwt(getJwt());

// methods
//The user will use a similar auth but different table so i can set other things

//need to change out the methods
export async function sendLoginRequest(email_address) {
  try {
    // console.log("Sending login request");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint = config.apiEndpoint + "/tenant_auth/send_login_code";
    const { data: message } = await http.post(fullApiEndpoint, {
      email_address: email_address,
    });

    //console.log("API Response");
    // console.log(message);

    return message;
  } catch (ex) {
    //console.log(ex);
  }
}

//need a LOGIN
export async function loginUser(accountPayLoad) {
  try {
    const fullApiEndpoint = config.apiEndpoint + "/tenant_auth/pwdless_login";
    const { data: response } = await http.post(fullApiEndpoint, accountPayLoad);

    // Store tokens and other information in the local storage
    localStorage.setItem(config.access_token, response.access_token);
    localStorage.setItem(config.refresh_token, response.refresh_token);
    localStorage.setItem(config.tenant_id, response.tenant_id);
    localStorage.setItem(config.is_paid, false);

    // Store first_name and last_name
    localStorage.setItem(config.first_name, response.first_name);
    localStorage.setItem(config.last_name, response.last_name);

    localStorage.setItem(config.email, accountPayLoad.email_address);
    localStorage.setItem(config.login_code, accountPayLoad.login_code);

    // Return the server message and the is_error property
    return {
      message: response.server_message,
      is_error: response.is_error,
      tenant_id: response.tenant_id,
      user_name: `${response.first_name} ${response.last_name}`,
    };
  } catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}

///tenant_auth/pwdless_register
/*
export async function registerUser(accountPayLoad) {
  try {
    //console.log("Registering user");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint =
      config.apiEndpoint + "/tenant_auth/pwdless_register";
    const { data } = await http.post(fullApiEndpoint, accountPayLoad);

    //console.log("API Response");
    //console.log(data);
    //set if no error

    // Return the server message and the is_error property
    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}
*/

//TODO: need to change these methods to the new ones

export async function getCurrentTenantProfileByEmail() {
  const token = localStorage.getItem(tokenKey);
  const email = localStorage.getItem(config.email);

  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_auth/fetch_tenant_by_email`;

  //console.log(`Email: ${email}`);
  //console.log(`Token: ${token}`);

  const { data: response } = await http.post(fullApiEndpoint, {
    email_address: email,
  });

  //console.log("API Response");
  //console.log(response);

  //remove expires_at, password, security_question, security_answer, login_code, _id
  delete response.expires_at;
  delete response.password;
  delete response.security_question;
  delete response.security_answer;
  delete response.login_code;
  delete response._id;

  /*
  const userData = {
    userId: response.user_pal_id,
    firstName: response.first_name,
    lastName: response.last_name,
    emailAddress: response.email_address,
    profileImg: response.profile_img,
    stripeCustomerId: response.stripe_customer_id,
  };*/

  return response;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  const fullApiEndpoint = config.apiEndpoint + "/tenant_auth/logout_tenant";

  const { data: response } = await http.post(
    fullApiEndpoint,
    new Tenant(
      localStorage.getItem(config.tenant_id),
      localStorage.getItem(config.first_name),
      "", // middle name
      localStorage.getItem(config.last_name),
      localStorage.getItem(config.email),
      localStorage.getItem(config.login_code),
      "", // expires_at
      localStorage.getItem(config.stripe_customer_id),
      "", // phone_number
      "", // created_at
      "", // updated_at
      "", // is_deleted
      "", // is_active
      "", // is_verified
      "", // campaign_id
      localStorage.getItem(config.profile_img)
    )
  );

  // Function to clear all relevant items from localStorage
  const clearLocalStorage = () => {
    const itemsToClear = [
      config.access_token,
      config.refresh_token,
      config.email,
      config.tenant_id,
      config.first_name,
      config.last_name,
      config.login_code,
      config.profile_img,
      config.stripe_customer_id,
      config.is_paid,
      config.user_type, // Added this as it was in the config.json file
    ];

    itemsToClear.forEach((item) => localStorage.removeItem(item));
  };

  if (response.is_error === false) {
    //console.log("User logged out successfully");
    clearLocalStorage();
    return true;
  } else {
    //still clear even if there's an error
    clearLocalStorage();
    return true; // Changed to return true as per the original logic
  }
}

export function getCurrentUser() {
  try {
    //get current user
    const jwt = localStorage.getItem(tokenKey);
    //alert(jwt);
    //if its an object
    //return jwtDecode(jwt);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

///tenant_auth/find_email_by_login_code
export async function findEmailByLoginCode(loginCode) {
  try {
    const fullApiEndpoint =
      config.apiEndpoint + "/tenant_auth/find_tenant_email_by_login_code";
    const { data } = await http.post(fullApiEndpoint, {
      login_code: loginCode,
    });

    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.info(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}
