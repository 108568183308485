import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { Upload, X, FileText, Plus } from "lucide-react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import FilePDFImagePreview from "../../../reusable/pdf/filePdfImagePreview";
// Web Worker code (same as before)
const workerCode = `
  self.onmessage = function(e) {
    const file = e.data.file;
    const reader = new FileReader();
    reader.onload = function(event) {
      self.postMessage({ id: e.data.id, result: event.target.result });
    };
    reader.readAsDataURL(file);
  }
`;

const workerBlob = new Blob([workerCode], { type: "application/javascript" });
const workerUrl = URL.createObjectURL(workerBlob);

// PreviewItem component (same as before)
const PreviewItem = React.memo(({ file, index, onRemove }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  return (
    <div
      className="position-relative"
      style={{ width: "120px", height: "120px" }}
    >
      {file.type.startsWith("image/") ? (
        preview && (
          <img
            src={preview}
            alt={`Preview ${index}`}
            className="w-100 h-100 object-fit-cover rounded"
          />
        )
      ) : (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded">
          <FileText size={48} />
        </div>
      )}
      <button
        onClick={(e) => onRemove(index, e)}
        className="btn btn-danger btn-sm position-absolute top-0 end-0 rounded-circle p-0"
        style={{ width: "24px", height: "24px" }}
        aria-label="Remove file"
      >
        <X size={16} />
      </button>
    </div>
  );
});

const PreviewExistingItem = React.memo(({ attachment, onRemove }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleOpenPreviewModal = () => setShowPreviewModal(true);
  const handleClosePreviewModal = () => setShowPreviewModal(false);

  const isPDForImage =
    attachment.file_type === "application/pdf" ||
    attachment.file_type.startsWith("image/");

  return (
    <>
      <div className="card h-100">
        <div className="card-body p-3">
          <div className="d-flex align-items-center">
            <div
              className="position-relative me-3"
              style={{
                width: "80px",
                height: "80px",
                cursor: "pointer",
                flexShrink: 0,
              }}
              onClick={handleOpenPreviewModal}
            >
              {attachment.file_type.startsWith("image/") ? (
                <img
                  src={attachment.url}
                  alt={attachment.file_name}
                  className="w-100 h-100 object-fit-cover rounded"
                />
              ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded">
                  <FileText size={40} />
                </div>
              )}
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1 text-truncate" style={{ fontSize: "0.9rem" }}>
                {attachment.file_name}
              </p>
              <small className="text-muted" style={{ fontSize: "0.8rem" }}>
                {attachment.file_size_formatted}
              </small>
            </div>
            <button
              className="btn btn-sm btn-outline-danger ms-2"
              onClick={onRemove}
              style={{ fontSize: "0.8rem", padding: "0.25rem 0.5rem" }}
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>

      <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{attachment.file_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPDForImage ? (
            <FilePDFImagePreview
              itemToPreview={attachment}
              setItemToDelete={() => {}}
              setShowConfirm={() => {}}
            />
          ) : (
            <div className="text-center">
              <FileText size={64} />
              <p>This file type cannot be previewed.</p>
              <a
                href={attachment.url}
                download
                className="btn btn-primary mt-2"
              >
                Download File
              </a>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePreviewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

const DocumentUploadSection = ({
  attachments,
  attachmentUploads,
  handleConfirmDocuments,
  handleDeleteAttachment,
  uploadBtnText = "Upload Documents",
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Data, setBase64Data] = useState([]);
  const workerRef = useRef();
  const queueRef = useRef([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    workerRef.current = new Worker(workerUrl);
    workerRef.current.onmessage = (e) => {
      setBase64Data((prev) => [
        ...prev,
        { id: e.data.id, data: e.data.result },
      ]);
      processQueue();
    };
    return () => workerRef.current.terminate();
  }, []);

  const processQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      const { file, id } = queueRef.current.shift();
      workerRef.current.postMessage({ file, id });
    }
  }, []);

  const addFiles = useCallback(
    (files) => {
      const newFiles = Array.from(files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      newFiles.forEach((file, index) => {
        const id = Date.now() + index;
        queueRef.current.push({ file, id });
      });

      processQueue();
    },
    [setSelectedFiles]
  );

  const handleFileChange = useCallback(
    (event) => {
      event.preventDefault();
      addFiles(event.target.files);
    },
    [addFiles]
  );

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      addFiles(e.dataTransfer.files);
    },
    [addFiles]
  );

  const removeFile = useCallback(
    (index, e) => {
      e.preventDefault();
      setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
      setBase64Data((prev) => prev.filter((_, i) => i !== index));
    },
    [setSelectedFiles, setBase64Data]
  );

  const handleUpload = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const newAttachmentUploads = base64Data.map((item, index) => ({
          file_name: selectedFiles[index].name,
          base64_data: item.data,
        }));

        // Call handleConfirmDocuments to save and upload the attachments
        if (handleConfirmDocuments) {
          // Pass both new uploads and existing attachments
          await handleConfirmDocuments(newAttachmentUploads, attachments);

          // Only show success toast if handleConfirmDocuments completes successfully
          toast.success(`${newAttachmentUploads.length} attachments uploaded!`);

          // Clear the selected files and base64 data after successful upload
          setSelectedFiles([]);
          setBase64Data([]);
        } else {
          console.error("handleConfirmDocuments is not defined");
          toast.error("Upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during upload:", error);
        toast.error("Upload failed. Please try again.");
      }
    },
    [base64Data, selectedFiles, handleConfirmDocuments, attachments]
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveExistingAttachment = useCallback(
    (index) => {
      handleDeleteAttachment(attachments[index].unique_file_name);
    },
    [attachments, handleDeleteAttachment]
  );

  return (
    <div className="document-upload-section">
      <h5 className="mb-3">Document Upload</h5>
      <div className="row">
        <div className="col-md-6">
          <div
            className={`mb-3 p-3 border rounded d-flex flex-column align-items-center justify-content-center ${
              isDragging ? "border-primary" : ""
            }`}
            style={{ height: "200px", cursor: "pointer" }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="d-none"
              ref={fileInputRef}
              accept="image/*,.pdf"
            />
            <Upload size={48} className="mb-2" />
            <p className="text-center mb-0">
              Click or drag and drop files here
            </p>
            <small className="text-muted">Supported: Images, PDFs</small>
          </div>
        </div>
      </div>
      {selectedFiles.length > 0 && (
        <div className="mb-3">
          <h6 className="mb-2">Selected Files</h6>
          <div className="d-flex flex-wrap gap-3">
            {selectedFiles.map((file, index) => (
              <PreviewItem
                key={index}
                file={file}
                index={index}
                onRemove={removeFile}
              />
            ))}
          </div>
          <Button
            variant="success"
            size="sm"
            className="mt-3"
            onClick={handleUpload}
            disabled={selectedFiles.length === 0}
          >
            <Upload size={16} className="me-1" />
            {uploadBtnText} ({selectedFiles.length})
          </Button>
        </div>
      )}

      <div className="existing-documents mt-4">
        <h6 className="mb-3">Existing Documents</h6>
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-2">
          {attachments.map((attachment, index) => (
            <div key={index} className="col">
              <PreviewExistingItem
                attachment={attachment}
                onRemove={() => handleRemoveExistingAttachment(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadSection;
