//handles the management of unit viewing
import http from "../../../../services/general/httpService";
import api from "../../../../constants/config.json";
import config from "../../../../constants/config.json";


//update_unit_viewing
export async function updateUnitViewing(unitViewing) {
    const fullApiEndpoint = api.apiEndpoint + `/unit_viewing/update_unit_viewing`;
    const {data: result} = await http.put(fullApiEndpoint, unitViewing);

    return result;
}

///get_unit_viewing/{unit_viewing_id}
export async function getUnitViewing(unitViewingId) {
    const fullApiEndpoint = api.apiEndpoint + `/unit_viewing/get_unit_viewing/${unitViewingId}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

///get_unit_viewings_by_campaign_id/{campaign_id}
export async function getAllAvailableViewingSlotsByCampaignId(campaignId) {
    const fullApiEndpoint = api.apiEndpoint + `/unit_viewing/get_all_available_unit_viewing_slots_by_campaign_id/${campaignId}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

///get_unit_viewings_by_tenant_id/{tenant_id}
export async function getUnitViewingsByTenantId(tenantId) {
    const fullApiEndpoint = api.apiEndpoint + `/unit_viewing/get_unit_viewings_by_tenant_id/${tenantId}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

///get_unit_viewing_by_campaign_id_and_tenant_id/{campaign_id}/{tenant_id}
export async function getUnitViewingByCampaignIdAndTenantId(campaignId, tenantId) {
    const fullApiEndpoint = api.apiEndpoint + `/unit_viewing/get_unit_viewing_by_campaign_id_and_tenant_id/${campaignId}/${tenantId}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}