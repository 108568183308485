import React from "react";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import SelectInputLabel from "../../../reusable/form/selectinputlabel";
import Input from "../../../reusable/form/Input";

const OtherOccupantAppliForm = ({ occupants = [], setOccupants }) => {
  const relationshipTypes = [
    "Spouse",
    "Child",
    "Friend",
    "Parent",
    "Sibling",
    "Other",
  ];

  const isOver18 = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age >= 18;
  };

  const handleChange = (index, field, value) => {
    const updatedOccupants = occupants.map((occupant, i) => {
      if (i === index) {
        const updatedOccupant = { ...occupant, [field]: value };
        if (field === "date_of_birth") {
          updatedOccupant.is_over_18 = isOver18(value);
          // Store as ISO string (YYYY-MM-DDTHH:mm:ss.sssZ)
          updatedOccupant.date_of_birth = new Date(value).toISOString();
        }
        return updatedOccupant;
      }
      return occupant;
    });
    setOccupants(updatedOccupants);
  };

  const handleAdd = () => {
    const newOccupant = {
      co_applicant_link_code: "",
      co_applicant_link_codes: [],
      primary_applicant_link_code: "",
      total_income: 0,
      created_at: new Date(),
      updated_at: new Date(),
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      relationship: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      is_over_18: true,
      date_of_birth: "", // Initialize as an empty string
    };
    setOccupants([...occupants, newOccupant]);
  };

  const handleRemove = (index) => {
    const updatedOccupants = occupants.filter((_, i) => i !== index);
    setOccupants(updatedOccupants);
  };

  const formatDateValue = (dateValue) => {
    if (!dateValue) return "";
    if (typeof dateValue === "string") {
      return dateValue.split("T")[0];
    }
    if (dateValue instanceof Date) {
      return dateValue.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <div>
      {occupants.map((occupant, index) => (
        <div key={index} className="row gy-4 mb-4">
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="first_name"
              value={occupant.first_name || ""}
              label="First Name"
              onChange={(e) =>
                handleChange(index, "first_name", e.target.value)
              }
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="last_name"
              value={occupant.last_name || ""}
              label="Last Name"
              onChange={(e) => handleChange(index, "last_name", e.target.value)}
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <SelectInputLabel
              name="relationship"
              value={occupant.relationship || ""}
              label="Relationship"
              onChange={(e) =>
                handleChange(index, "relationship", e.target.value)
              }
            >
              <option value="">Select...</option>
              {relationshipTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </SelectInputLabel>
          </div>
          <div className="col-xxl-3 col-md-6">
            <Input
              showIcon
              name="date_of_birth"
              value={formatDateValue(occupant.date_of_birth)}
              label="Date of Birth"
              onChange={(date) => handleChange(index, "date_of_birth", date)}
              type="date"
            />
          </div>
          {occupant.is_over_18 && (
            <>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="email"
                  value={occupant.email || ""}
                  label="Email"
                  onChange={(e) => handleChange(index, "email", e.target.value)}
                />
              </div>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="phone_number"
                  value={occupant.phone_number || ""}
                  label="Phone Number"
                  onChange={(e) =>
                    handleChange(index, "phone_number", e.target.value)
                  }
                />
              </div>
            </>
          )}
          <div className="col-xxl-1 col-md-6 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-danger btn-icon waves-effect waves-light"
              onClick={() => handleRemove(index)}
            >
              <i className="ri-delete-bin-5-line"></i>
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-success w-lg waves-effect waves-light"
        onClick={handleAdd}
      >
        <i className="ri-add-fill"></i> Add Occupant
      </button>
    </div>
  );
};

export default OtherOccupantAppliForm;
