import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchPropertyAdvertById } from '../../../../services/property/advertisement/propertyAdvertisement';

import { Button, Carousel, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap'; // or whichever tooltip component you're using
//add analytics checks here
import { toast } from 'react-toastify';
import { formatCurrency } from '../../../../services/utilis/currencyUtils';
const PropertyUnitAdvert = ({ isDarkMode }) => {
  const [productionUrl, setProductionUrl] = useState(
    'https://tenant.gurenter.com'
  );
  const [isLoading, setIsLoading] = useState();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { campaignId } = useParams();
  //update the state next
  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advert_id: '',
    landlord_id: '',
    property_unit_id: '',
    date_advert_ends: '',
    campaign_id: '',
    property_unit: {
      property_unit_id: '',
      property_id: '',
      market_rent_amount: 0,
      number_of_bathrooms: 0,
      number_of_bedrooms: 0,
      square_footage: 0,
      unit_name: '',
      //new
      number_of_half_bathrooms: '',
      number_of_rooms: '',
    },
    security_deposit: 0,
    screening_pack_id: '',
    currency_id: '',
    currency_name: '',
    is_advert_expired: false,
    rental_unit_price: 0,
    rental_unit_amt_currency: '',
    unit_advert_image_urls: [],
  });

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleFullscreen = (image) => {
    setFullscreenImage(image);
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    setIsFullscreen(false);
    setFullscreenImage(null);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    if (!campaignId) {
      setIsLoading(false);
      return;
    }
    getAdvertDetail();
    //after loading

    setIsLoading(false);
  }, []);

  const getAdvertDetail = async () => {
    const propertyUnitAdvert = await fetchPropertyAdvertById(campaignId);

    if (propertyUnitAdvert?.error) {
      toast.error('An error occurred while fetching the property advert');
      setErrorOccurred(true);
      return;
    }

    console.log(propertyUnitAdvert);
    setPropertyUnitAdvert(propertyUnitAdvert);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && (
        <div id='global-loader'>
          {/*	<img src={loader} className='loader-img' alt='Loader' /> */}
        </div>
      )}
      {/*/Loader */}

      {errorOccurred ? (
        <div className='page-content' style={{ paddingBottom: '10%' }}>
          <div class='row'>
            <div class='col-xl-8' id='card-slide'>
              <div className='alert alert-danger' role='alert'>
                <h4 className='alert-heading'>Error</h4>
                <p>
                  An error occurred while fetching the property advertisement.
                  It may not exist or is expired. Please try again later or
                  contact the landlord or property manager.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : !campaignId && !errorOccurred ? (
        <div
          className='page-content'
          data-bs-theme={isDarkMode ? 'dark' : 'light'}
          style={{ paddingBottom: '10%' }}
        >
          <div class='row'>
            <div class='col-xl-8' id='card-slide'>
              <div className='alert alert-warning' role='alert'>
                <h4 className='alert-heading'>Missing Campaign ID</h4>
                <p>
                  A Campaign ID is required in the URL to present an active
                  property unit for rent. Please ensure a valid Campaign ID is
                  provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='page-content'
          data-bs-theme={isDarkMode ? 'dark' : 'light'}
          style={{ paddingBottom: '10%' }}
        >
          <div class='row'>
            <div class='col-xl-4' id='card-slide'>
              {/*Carousel */}
              <div>
                <Carousel data-bs-theme='dark'>
                  {propertyUnitAdvert &&
                    propertyUnitAdvert.unit_advert_image_urls.map(
                      (image, index) => (
                        <Carousel.Item key={index}>
                          <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              backgroundColor: '#f8f9fa', // Light gray background
                              minHeight: '150px',
                              maxHeight: '400px',
                              width: '100%',
                            }}
                          >
                            <img
                              className='d-block'
                              src={image.url}
                              alt={`Slide ${index + 1}`}
                              style={{
                                maxHeight: '400px',
                                objectFit: 'contain',
                                maxWidth: '100%',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleFullscreen(image.url)}
                            />
                          </div>
                          <Carousel.Caption>
                            <h5>{`Slide ${index + 1}`}</h5>
                          </Carousel.Caption>
                        </Carousel.Item>
                      )
                    )}
                </Carousel>

                <Modal
                  show={isFullscreen}
                  onHide={exitFullscreen}
                  centered
                  size='lg'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Unit Image</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ padding: 0 }}>
                    <img
                      src={fullscreenImage}
                      alt='Fullscreen'
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                    />
                  </Modal.Body>
                </Modal>
              </div>

              <div class=''>
                <div class='card'>
                  <div class='card-body'>
                    <div class='d-flex position-relative'>
                      <div>
                        <Button
                          hidden
                          size='sm'
                          href='javascript:void(0);'
                          class='btn btn-primary stretched-link'
                        >
                          Share
                        </Button>

                        <h5 class='mt-0' style={{ paddingTop: '1%' }}>
                          {propertyUnitAdvert?.property_unit?.unit_name
                            ?.toUpperCase()
                            .charAt(0) +
                            propertyUnitAdvert?.property_unit?.unit_name?.slice(
                              1
                            )}
                        </h5>
                        <p>
                          The location of the property{' '}
                          <b>
                            {' '}
                            {propertyUnitAdvert?.property_unit?.unit_name?.toUpperCase()}
                          </b>
                          . Will be provided only if you have been approved to
                          view the property. This will be done by an initial
                          screening process.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class='card-header'>
                    <div class='d-flex align-items-center'>
                      <div class='flex-grow-1'>
                        <h6 class='card-title mb-0'>
                          {propertyUnitAdvert.property_unit && (
                            <b>
                              {formatCurrency(
                                propertyUnitAdvert.rental_unit_price
                              )}{' '}
                              {propertyUnitAdvert.currency_name} {'/'}
                              {' month'}
                            </b>
                          )}
                        </h6>
                      </div>
                      <div class='flex-shrink-0'></div>
                    </div>
                  </div>
                  <div class='card-body collapse show' id='collapseexample1'>
                    <div class='d-flex'>
                      <div class='flex-shrink-0'>
                        <i class='ri-checkbox-circle-fill text-primary'></i>
                      </div>
                      <div class='flex-grow-1 ms-2 text-muted'>
                        <div class='flex-grow-1 ms-2'>
                          <p class='text-muted mb-0'>Available Until</p>
                          <h5 class='card-title mb-1'>
                            {new Date(
                              propertyUnitAdvert.date_advert_ends
                            ).toLocaleString('default', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='d-flex mt-2 col-6'>
                        <div class='flex-shrink-0'>
                          <i class='ri-checkbox-circle-fill text-primary'></i>
                        </div>
                        <div class='flex-grow-1 ms-2 text-muted'>
                          <div class='flex-grow-1 ms-2'>
                            <p class='text-muted mb-0'>Security Deposit</p>
                            <h5 class='card-title mb-1'>
                              {formatCurrency(
                                propertyUnitAdvert.security_deposit
                              )}{' '}
                              {propertyUnitAdvert.currency_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Square Foot and things usefull */}
                    {propertyUnitAdvert.property_unit && (
                      <div class='row'>
                        <div class='d-flex mt-2 col-6'>
                          <div class='flex-shrink-0'>
                            <i class='ri-checkbox-circle-fill text-primary'></i>
                          </div>
                          <div class='flex-grow-1 ms-2 text-muted'>
                            <div class='flex-grow-1 ms-2'>
                              <p class='text-muted mb-0'>
                                Interior Square Footage
                              </p>
                              <h5 class='card-title mb-1'>
                                {
                                  propertyUnitAdvert.property_unit
                                    .square_footage
                                }{' '}
                                sqft
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='row mt-4'>
                      <div className='col mb-3'>
                        {/* Copy link to clipboard */}
                        <button
                          className='btn btn-primary w-lg waves-effect waves-light'
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${productionUrl}/advert/${propertyUnitAdvert.campaign_id}`
                            );

                            toast.success('Link copied to clipboard');
                          }}
                        >
                          Share Link
                        </button>
                      </div>

                      <div className='col mb-3'>
                        {propertyUnitAdvert.is_advert_expired ? (
                          <button
                            className='btn btn-success w-lg w-100 fw-bold waves-effect waves-light'
                            disabled
                          >
                            Sign Up
                          </button>
                        ) : (
                          <Link
                            to={`/rentalSignUp/${propertyUnitAdvert.campaign_id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-success w-lg w-100 fw-bold waves-effect waves-light'
                          >
                            Sign Up
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className='d-flex mt-4'>
                      {propertyUnitAdvert.is_advert_expired ? (
                        <h4 style={{ color: 'red' }}>
                          We are no longer accepting applicants
                        </h4>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>

            <div class='col-xxl-4 row-cols-lg-1 row-cols-1'>
              <div class='row row-cols-xxl-3 row-cols-lg-3 row-cols-1'>
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-ruler-combined la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Square Footage</p>
                        <h5 class='card-title mb-1'>
                          {propertyUnitAdvert?.property_unit.square_footage}{' '}
                          sqft
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-bed la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Bedrooms</p>
                        <h5 class='card-title mb-1'>
                          {propertyUnitAdvert?.property_unit.number_of_bedrooms}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-bath la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Bathrooms</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.property_unit
                              .number_of_bathrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-toilet la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Half Bathrooms</p>
                        <h5 class='card-title mb-1'>
                          {' '}
                          {
                            propertyUnitAdvert?.property_unit
                              ?.number_of_half_bathrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-door-open la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Rooms</p>
                        <h5 class='card-title mb-1'>
                          {propertyUnitAdvert?.propertyUnit?.number_of_rooms}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
              </div>

              {/* end row */}
            </div>
            {/* end col */}

            {/*Another column like analytics could come here */}
          </div>
          {/*end row */}
        </div>
      )}
      {/* Optional Amenities Section */}
    </React.Fragment>
  );
};

export default PropertyUnitAdvert;
