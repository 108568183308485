//handles the login and registration
import http from "../general/httpService";
import config from "../../constants/config.json";

//declaring the constants
const tokenKey = config.access_token;
const refreshTokenKey = config.refresh_token;
const email = config.email;
const userKey = config.tenant_id;

//Need to send the data to the server , prescreening model,
//firebase account, the go to the portal

http.setJwt(getJwt());

//need a LOGIN
export async function loginTenant(accountPayLoad) {
  try {
    console.log("Logging in to firebase");
    console.log(
      `Email: ${accountPayLoad.emailAddress} Password: ${accountPayLoad.password}`
    );

    //    const userCredential = await signInWithEmailAndPassword(firebase_auth, accountPayLoad.emailAddress, accountPayLoad.password);
    //   console.log("User authenticated successfully:", userCredential);

    // Get the user token
    //  const firebaseUserToken = userCredential.user.accessToken;

    const fullApiEndpoint =
      config.apiEndpoint + "/tenant_auth/api/pwdless_login";
    const { data: jwt } = await http.post(fullApiEndpoint, accountPayLoad);

    // localStorage.setItem(firebaseTokenKey, firebaseUserToken);

    // const userData = response.data;

    // const token = response.headers['x-auth-token'];

    localStorage.setItem(tokenKey, jwt);
    localStorage.setItem(email, accountPayLoad.emailAddress);
    //localStorage.setItem(email, accountPayLoad.emailAddress);

    //return userData;
  } catch (ex) {
    console.log(ex);
  }
}

//Initial registration
//add the create firebase later
export async function createTenantPreScreening(prescreeningData) {
  const fullApiEndpoint = config.apiEndpoint + `/tenant_auth/create_tenant`;
  const response = await http.post(fullApiEndpoint, prescreeningData);
  //print the response
  // Extract the landlordData from the response
  /*
  const userData = response.data;

  console.log("API Response");
  console.log(userData);

  const token = response.headers["x-auth-token"];

  console.log(token);
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(email, userData.emailAddress);
  */

  return response;
}

export function getCurrentUserProfileById(tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_auth/get_tenant_by_id/${tenantId}`;
  return http.get(fullApiEndpoint);
}

/*
export function getCurrentUserProfileByEmail(emailAddress) {
  const token = localStorage.getItem(tokenKey);
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_auth/get_tenant_by_email/${emailAddress}`;

  return http.get(fullApiEndpoint, {
    headers: {
      "x-auth-token": token,
    },
  });
}
*/
//submitting the entire snapshot
//
export async function submitTenantApplicationFormToLandlord(prescreeningData) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenantApplicManagement/api/update_tenant_rental_application_snapshot`;
  const response = await http.post(fullApiEndpoint, prescreeningData);
  //print the response
  // Extract the landlordData from the response
  const userData = response.data;

  console.log("API Response");
  console.log(userData);

  const token = response.headers["x-auth-token"];

  console.log(token);
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(email, userData.emailAddress);

  return response;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    //get current user
    const jwt = localStorage.getItem(tokenKey);
    //alert(jwt);
    //if its an object
    //return jwtDecode(jwt);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
