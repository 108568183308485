export const getActionTypeText = (actionType) => {
  switch (actionType) {
    case "none":
      return "No action required";
    case "schedule_viewing":
      return "Schedule Viewing";
    case "complete_application":
      return "Complete Application";
    case "upload_document":
      return "Upload Document";
    case "schedule_interview":
      return "Schedule Interview";
    case "sign_lease":
      return "Sign Lease";
    case "submit_payment":
      return "Submit Payment";
    case "schedule_move_in":
      return "Schedule Move-In";
    case "report_issue":
      return "Report Issue";
    case "request_maintenance":
      return "Request Maintenance";
    case "update_contact_info":
      return "Update Contact Info";
    case "review_policies":
      return "Review Policies";
    case "confirm_details":
      return "Confirm Details";
    default:
      return "Perform Action";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    case "In Progress":
      return "warning";
    default:
      return "default";
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "in_progress":
      return "In Progress";
    default:
      return "Pending";
  }
};

export const getApplicationStatusButtonText = (applicationStatus) => {
  switch (applicationStatus.toUpperCase()) {
    case "NOT_STARTED":
      return "Start Application";
    case "IN_PROGRESS":
      return "Continue Application";
    case "SUBMITTED":
      return "View Application";
    case "APPROVED":
      return "View Approved Application";
    case "REJECTED":
      return "View Application Status";
    default:
      return "View Application";
  }
};
