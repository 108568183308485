import http from "../../../services/general/httpService";
import api from "../../../constants/config.json";

//universal_list/get_or_create_tenant_universal_list/{tenant_id}/{campaign_id}
export async function getOrCreateTenantUniversalList(tenantId, campaignId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/universal_list/get_or_create_tenant_universal_list/${tenantId}/${campaignId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//need to implement this
export async function updateTenantUniversalListItem(
  tenantUniversalListItemId,
  isCompleted
) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/universal_list/update_tenant_universal_list_item/${tenantUniversalListItemId}/${isCompleted}`;
  const { data: result } = await http.put(fullApiEndpoint);

  return result;
}
