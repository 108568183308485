import React from "react";

const ShimmerEffect = ({ type = "card", height, width, className = "" }) => {
  const getShimmerContent = () => {
    switch (type) {
      case "circle":
        return <div className="shimmer-circle"></div>;
      case "line":
        return (
          <div
            className="shimmer-line"
            style={{ width: width || "100%" }}
          ></div>
        );
      case "chart":
        return (
          <div
            className="shimmer-chart"
            style={{ height: height || "200px" }}
          ></div>
        );
      case "card":
      default:
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="shimmer-circle"></div>
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="shimmer-line w-75 mb-2"></div>
              <div className="shimmer-line w-50"></div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`shimmer-wrapper ${className}`} style={{ height, width }}>
      {getShimmerContent()}
    </div>
  );
};

export default ShimmerEffect;
