import React, { useEffect, useState, useReducer, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Joi from "joi-browser";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import TextInputLabel from "../../reusable/form/textinputlabel";
import CheckboxInput from "../../reusable/form/checkboxInput";
import PasswordInput from "../../reusable/form/passwordInput";
import Input from "../../reusable/form/Input";
import CurrentAddressAppliForm from "./sections/currentAddressAppliForm";
import PreviousAddressAppliForm from "./sections/previousAddressAppliForm";
import OtherOccupantAppliForm from "./sections/otherApplicantAppliForm";
import EmploymentAndIncomeForm from "./sections/employmentAndIncomeForm";
import EmergencyContactForm from "./sections/emergencyContactForm";
import PersonalReferenceForm from "./sections/personalReferenceForm";
import {
  deleteAttachment,
  getTenantRentalApplicationByCampaignId,
  updateTenantRentalApplication,
} from "../../../services/tenant/masterApplication/tenantMainApplicationManagement";
import { devLog } from "../../../utils/logger";

// Import the new section components
import PersonalInformationSection from "./sections/PersonalInformationSection";
import CurrentAddressSection from "./sections/currentAddressAppliForm";
import PreviousAddressSection from "./sections/previousAddressAppliForm";
import OtherOccupantsSection from "./sections/otherApplicantAppliForm";
import EmploymentAndIncomeSection from "./sections/employmentAndIncomeForm";
import ReferencesAndContactsSection from "./sections/personalReferenceForm";
import DocumentUploadSection from "./sections/DocumentUploadSection"; // New component for document uploads
import "../../../styles/progressIndicator.css";
import FinalStep from "./FinalStep";
import { getCurrentTenantProfileByEmail } from "../../../services/authentication/tenantAuth";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"; // Make sure to install this package
import config from "../../../constants/config.json";
const PrimaryApplicationForm = ({ isDarkMode }) => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showSsnTrn, setshowSsnTrn] = useState(false);
  const [showDriversLicenseNumber, setshowDriversLicenseNumber] =
    useState(false);
  const { campaignIdFromUrl } = useParams();
  const [campaignId, setCampaignId] = useState(campaignIdFromUrl);
  const [showDropdown, setShowDropdown] = useState(!campaignIdFromUrl);

  const [fullName, setFullName] = useState(
    `${localStorage.getItem(config.first_name)} ${localStorage.getItem(
      config.last_name
    )}`
  );
  const navigate = useNavigate();
  //get the tenant profile to set some of the values
  const [tenantProfile, setTenantProfile] = useState({
    tenant_id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    stripe_customer_id: "",
    phone_number: "",
    campaign_id: "",
    profile_image_url: "",
    pre_screening_detail: {
      duration_of_current_residence: "",
      total_monthly_income: "",
      sources_of_income: [
        {
          job_category_id: "",
          job_category: "",
          created_at: Date(),
          updated_at: Date(),
        },
      ],
      number_of_occupants: "",
      reason_for_leaving: "",
      desired_move_in_date: Date(),
      does_any_one_smoke: false,
      has_pets: false,
      describe_pets: "",
    },
  });
  const [tenantRentalApplicationDetails, setTenantRentalApplicationDetails] =
    useState({
      tenant_rental_application_id: "",
      tenant_id: "",
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",

      property_unit_id: "",
      campaign_id: "",
      co_applicant_link_code: "",
      application_status: "",
      application_date: "",
      has_passed_pre_screening: false,
      has_passed_viewing_screening: false,
      has_passed_application_screening: false,
      has_passed_application_screening_date: "",
      has_paid_application_fee: false,
      has_signed_lease: false,
      has_paid_first_month_rent: false,
      has_paid_last_month_rent: false,
      has_paid_security_deposit: false,
      needs_guarantor: false,
      has_passed_background_check: false,
      has_passed_credit_check: false,
      has_passed_eviction_check: false,
      has_passed_criminal_check: false,
      has_been_selected_for_viewing: false,
      has_been_selected_for_the_rental: false,
      has_been_hard_rejected: false,
      //to tell the application is ready to be submitted start
      has_completed_application: false,
      campaign_completed_at: "",
      signed_acceptance_of_terms: false,
      signed_acceptance_of_terms_at: "",
      signed_acceptance_of_terms_name: "",
      //to tell the application is ready to be submitted end
      has_completed_the_universal_list: false,
      co_applicant_connections: [
        {
          co_applicant_link_code: "",
          co_applicant_link_codes: [],
          primary_applicant_link_code: "",
          total_income: 0,
          created_at: new Date(),
          updated_at: new Date(),

          first_name: "",
          last_name: "",
          phone_number: "",
          email: "",
          relationship: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
          is_over_18: true,
          date_of_birth: new Date(),
        },
      ],
      prescreening_info: {
        duration_of_current_residence: 0,
        total_monthly_income: 0,
        sources_of_income: [],
        number_of_occupants: 0,
        reason_for_leaving: "",
        desired_move_in_date: "",
        does_any_one_smoke: false,
        has_pets: false,
        describe_pets: "",
      },
      unit_viewing: {
        unit_viewing_id: "",
        campaign_id: "",
        duration: 0,
        end_time: "",
        note: "",
        start_time: "",
        status: "",
        tenant_id: "",
        tenant_name: "",
        is_expired: "",
        is_cancelled: "",
        created_at: "",
        modified_at: "",
        has_been_viewed: false,
      },
      personal_references: [
        {
          name: "",
          phone_number: "",
          email: "",
          relationship: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
        },
      ],
      emergency_contacts: [
        {
          name: "",
          phone_number: "",
          email: "",
          relationship: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
        },
      ],
      current_addresses: [
        {
          address_type: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
          duration_of_residence: 0,
          reason_for_leaving: "",
          is_current_residence: false,
          date_of_residence_start: new Date(),
          date_of_residence_end: new Date(),
          landlord_name: "",
          landlord_phone_number: "",
          landlord_email: "",
          monthly_rent: 0,
        },
      ],
      employment_income_info: [
        {
          employer_name: "",
          employer_address: "",
          employer_city: "",
          employer_state: "",
          employer_zip_code: "",
          employer_phone_number: "",
          employer_email: "",
          employer_website: "",
          employer_start_date: new Date(),
          employer_end_date: new Date(),
          employer_reason_for_leaving: "",
          employer_supervisor_name: "",
          employer_supervisor_phone_number: "",
          employer_supervisor_email: "",
          job_category_id: "",
          job_category: "",
          annual_income: 0,
        },
      ],
      previous_addresses: [
        {
          address_type: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
          duration_of_residence: 0,
          reason_for_leaving: "",
          is_current_residence: false,
          date_of_residence_start: new Date(),
          date_of_residence_end: new Date(),
          landlord_name: "",
          landlord_phone_number: "",
          landlord_email: "",
          monthly_rent: 0,
        },
      ],
      tenant_signature: {
        signed_by: "",
        signature_url: "",
        date: "",
      },
      vehicles: [
        {
          vehicle_type: "",
          vehicle_year: "",
          vehicle_make: "",
          vehicle_model: "",
          vehicle_vin: "",
          vehicle_license_plate: "",
          vehicle_license_plate_state: "",
        },
      ],
      media_proof_references: [
        {
          proof_reference_id: "",
          proof_reference_url: "",
          proof_reference_type: "",
          proof_reference_description: "",
          uploaded_at: new Date(),
          uploaded_by: "",
        },
      ],
      //existing attachments
      attachments: [
        {
          url: "",
          file_name: "",
          file_size: 0,
          file_size_formatted: "",
          file_type: "",
          unique_file_name: "",
        },
      ],
      attachment_uploads: [
        {
          file_name: "",
          base64_data: "",
        },
      ],
    });

  const [errors, setErrors] = useState({});
  const [Loading, setLoading] = useState({ isLoading: false });

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 8; // Increased to 8 steps

  const steps = [
    { title: "Personal Information", component: PersonalInformationSection },
    { title: "Current Address", component: CurrentAddressSection },
    { title: "Previous Address", component: PreviousAddressSection },
    { title: "Other Occupants", component: OtherOccupantsSection },
    { title: "Employment and Income", component: EmploymentAndIncomeSection },
    {
      title: "References and Contacts",
      component: ReferencesAndContactsSection,
    },
    { title: "Document Upload", component: DocumentUploadSection }, // New step
    { title: "Review and Submit", component: FinalStep },
  ];

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    setLoading({ isLoading: true });
    //if no campaign id then get the latest from the tenant profile
    //must keep the latest campaign id up to date with the tenant profile
    window.scrollTo(0, 0);
    fetchTenantProfile();
    fetchTenantRentalApplicationDetails();
    setLoading({ isLoading: false });
  }, [campaignId]);

  const fetchTenantProfile = async () => {
    try {
      const tenantProfile = await getCurrentTenantProfileByEmail();
      if (tenantProfile) {
        console.log("Tenant Profile", tenantProfile);
        setTenantProfile(tenantProfile);
        //if the campaign id is not set in the url then set it
        if (!campaignId && tenantProfile.campaign_id) {
          setCampaignId(tenantProfile.campaign_id);
          //set it in the url
          navigate(`/primaryApplicationForm/${tenantProfile.campaign_id}`);
        }
        //use the tenant profile to set the tenant rental application details related to the profile
        //related to tenantRentalApplicationDetails
        //if the tenant rental application details is not set for the following fields then set it
        //if they are empty strings then set it
        if (
          !tenantRentalApplicationDetails.first_name ||
          tenantRentalApplicationDetails.first_name === "" ||
          !tenantRentalApplicationDetails.last_name ||
          tenantRentalApplicationDetails.last_name === "" ||
          !tenantRentalApplicationDetails.email_address ||
          tenantRentalApplicationDetails.email_address === "" ||
          !tenantRentalApplicationDetails.phone_number ||
          tenantRentalApplicationDetails.phone_number === ""
        ) {
          setTenantRentalApplicationDetails((prev) => ({
            ...prev,
            first_name: prev.first_name || tenantProfile.first_name,
            last_name: prev.last_name || tenantProfile.last_name,
            email_address: prev.email_address || tenantProfile.email_address,
            phone_number: prev.phone_number || tenantProfile.phone_number,
          }));
        }
      }
    } catch (error) {
      toast.error("Failed to fetch tenant profile");
    }
  };

  const fetchTenantRentalApplicationDetails = async () => {
    try {
      const { data: applicationDetails } =
        await getTenantRentalApplicationByCampaignId(campaignId);
      if (applicationDetails) {
        devLog("Tenant Rental Application Details", applicationDetails);
        //need to apply this to the form
        setTenantRentalApplicationDetails(applicationDetails);
      }
    } catch (error) {
      toast.error("Failed to fetch application details");
    }
  };

  const handleChange = (eventOrValue, name) => {
    let value, fieldName;

    if (eventOrValue && eventOrValue.target) {
      ({ value, name: fieldName } = eventOrValue.target);
    } else {
      value = eventOrValue;
      fieldName = name;
    }

    setTenantRentalApplicationDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateTenantRentalApplication(tenantRentalApplicationDetails);
      toast.success("Application updated successfully");
    } catch (error) {
      toast.error("Failed to update application");
    }
  };
  const updateApplicationDetails = (field, value) => {
    setTenantRentalApplicationDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePreSave = async () => {
    try {
      await updateTenantRentalApplication(tenantRentalApplicationDetails);
      toast.success("Saved Successfully");
    } catch (error) {
      devLog("Error saving application:", error);
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 500)
      ) {
        const errorMessage =
          error.response.data.detail || "An error occurred while saving.";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred while saving.");
      }
    }
  };

  const updateTenantRentalApplicationDetails = (field, value) => {
    setTenantRentalApplicationDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const refreshRentalFormData = useCallback(async () => {
    try {
      setLoading({ isLoading: true });
      await fetchTenantProfile();
      await fetchTenantRentalApplicationDetails();
      setLoading({ isLoading: false });
      toast.success("Rental form data refreshed successfully");
    } catch (error) {
      console.error("Error refreshing rental form data:", error);
      toast.error("Failed to refresh rental form data");
      setLoading({ isLoading: false });
    }
  }, [campaignId]);

  const handleConfirmDocuments = useCallback(
    async (newAttachmentUploads) => {
      try {
        // Update the state using a function to ensure we're working with the latest state
        setTenantRentalApplicationDetails((prev) => {
          const updatedDetails = {
            ...prev,
            attachment_uploads: [
              ...(prev.attachment_uploads || []),
              ...newAttachmentUploads,
            ],
          };

          // Log the updated details
          devLog("Updated Application Details", updatedDetails);

          // Immediately save the changes
          updateTenantRentalApplication(updatedDetails)
            .then(() => {
              // Refresh the rental form data after successful update
              refreshRentalFormData();
            })
            .catch((error) => {
              devLog("Error saving documents:", error);
              if (
                error.response &&
                (error.response.status === 400 || error.response.status === 500)
              ) {
                const errorMessage =
                  error.response.data.detail ||
                  "An error occurred while saving documents.";
                toast.error(errorMessage);
              } else {
                toast.error(
                  "An unexpected error occurred while saving documents."
                );
              }
            });

          return updatedDetails;
        });
      } catch (error) {
        console.error("Error in handleConfirmDocuments:", error);
        toast.error("An unexpected error occurred");
      }
    },
    [refreshRentalFormData]
  );

  const handleDeleteAttachment = async (uniqueFileName) => {
    var response = await deleteAttachment(
      tenantRentalApplicationDetails.tenant_rental_application_id,
      uniqueFileName
    );
    if (response) {
      toast.success("Attachment deleted successfully");
      await refreshRentalFormData();
    } else {
      toast.error("Failed to delete attachment");
    }
  };

  // Add this function in the PrimaryApplicationForm component
  const goToSection = (sectionIndex) => {
    setCurrentStep(sectionIndex);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signature, setSignature] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [signatureError, setSignatureError] = useState("");

  const openModal = () => {
    if (tenantRentalApplicationDetails.has_been_hard_rejected) {
      toast.error(
        "Your application has been rejected. You cannot submit at this time."
      );
    } else if (tenantRentalApplicationDetails.has_completed_application) {
      toast.info(
        "This application has already been submitted. You cannot resubmit it."
      );
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSignature("");
    setIsAgreed(false);
  };

  const handleSignatureChange = (e) => {
    setSignature(e.target.value);
    setSignatureError("");
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleModalSubmit = async () => {
    if (isAgreed && signature.trim() !== "") {
      const normalizedSignature = signature.replace(/\s+/g, "").toLowerCase();
      const normalizedFullName = fullName.replace(/\s+/g, "").toLowerCase();

      if (normalizedSignature === normalizedFullName) {
        closeModal();
        try {
          // Check again if the application has already been completed
          if (tenantRentalApplicationDetails.has_completed_application) {
            toast.info(
              "This application has already been submitted. You cannot resubmit it."
            );
            return;
          }

          // Update the application details
          const updatedDetails = {
            ...tenantRentalApplicationDetails,
            application_status: "submitted",
            has_completed_application: true,
            campaign_completed_at: new Date().toISOString(),
            signed_acceptance_of_terms: true,
            signed_acceptance_of_terms_at: new Date().toISOString(),
            signed_acceptance_of_terms_name: signature,
          };

          // Call the API to update the application
          await updateTenantRentalApplication(updatedDetails);

          // Show success toast
          toast.success(
            "Congratulations! Your application has been submitted successfully. The landlord/property manager will be in touch with you shortly."
          );

          // Navigate to the dashboard
          navigate("/dashboard");
        } catch (error) {
          console.error("Error submitting application:", error);
          toast.error("Failed to submit application. Please try again.");
        }
      } else {
        setSignatureError(
          "Signature must match your full name (ignoring spaces and case)."
        );
      }
    } else {
      alert("Please agree to the terms and provide your signature.");
    }
  };

  return (
    <React.Fragment>
      {Loading.isLoading && (
        <div
          id="global-loader"
          style={{
            zIndex: "99",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "absolute",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      )}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="card-title mb-0">
                        Primary Application Form
                      </h4>
                      <h6
                        className="card-subtitle text-muted mt-1"
                        style={{ paddingTop: "0.5%" }}
                      >
                        This is your copy. When submitting an application to a
                        landlord, they will only get a snapshot of this
                        information.
                      </h6>
                    </div>
                    {currentStep === totalSteps ? (
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={openModal}
                        disabled={
                          tenantRentalApplicationDetails.has_been_hard_rejected ||
                          tenantRentalApplicationDetails.has_completed_application
                        }
                      >
                        Submit Application
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handlePreSave}
                      >
                        Save Progress
                      </button>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="progress-indicator mb-4">
                      {steps.map((step, index) => (
                        <div key={index} className="step-container">
                          <div className="step-content">
                            <div
                              className={`step-circle ${
                                index + 1 < currentStep
                                  ? "completed"
                                  : index + 1 === currentStep
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {index + 1 < currentStep ? (
                                <i className="fas fa-check"></i>
                              ) : (
                                index + 1
                              )}
                            </div>
                            <div className="step-title">{step.title}</div>
                          </div>
                          {index < steps.length - 1 && (
                            <div
                              className={`step-connector ${
                                index + 1 < currentStep ? "completed" : ""
                              }`}
                            ></div>
                          )}
                        </div>
                      ))}
                    </div>

                    {tenantRentalApplicationDetails.has_been_hard_rejected && (
                      <div className="alert alert-danger mb-4" role="alert">
                        Your application has been rejected. You cannot submit at
                        this time.
                      </div>
                    )}

                    {tenantRentalApplicationDetails.has_completed_application && (
                      <div className="alert alert-info mb-4" role="alert">
                        This application has already been submitted. You cannot
                        resubmit it.
                      </div>
                    )}

                    <form onSubmit={(e) => e.preventDefault()}>
                      {currentStep === 1 && (
                        <PersonalInformationSection
                          tenantRentalApplicationDetails={
                            tenantRentalApplicationDetails
                          }
                          setTenantRentalApplicationDetails={
                            setTenantRentalApplicationDetails
                          }
                          tenantProfile={tenantProfile}
                          errors={errors}
                        />
                      )}
                      {currentStep === 2 && (
                        <CurrentAddressSection
                          currentAddressDetail={
                            tenantRentalApplicationDetails
                              .current_addresses[0] || {}
                          }
                          setCurrentAddressDetail={(newAddress) =>
                            updateApplicationDetails("current_addresses", [
                              newAddress,
                            ])
                          }
                        />
                      )}
                      {currentStep === 3 && (
                        <PreviousAddressSection
                          previousAddressDetail={
                            tenantRentalApplicationDetails
                              .previous_addresses[0] || {}
                          }
                          setPreviousAddressDetail={(newAddress) =>
                            updateApplicationDetails("previous_addresses", [
                              newAddress,
                            ])
                          }
                        />
                      )}
                      {currentStep === 4 && (
                        <OtherOccupantsSection
                          occupants={
                            tenantRentalApplicationDetails.co_applicant_connections ||
                            []
                          }
                          setOccupants={(newOccupants) =>
                            setTenantRentalApplicationDetails((prev) => ({
                              ...prev,
                              co_applicant_connections: newOccupants,
                            }))
                          }
                        />
                      )}
                      {currentStep === 5 && (
                        <EmploymentAndIncomeSection
                          employmentIncomeInfos={
                            tenantRentalApplicationDetails.employment_income_info ||
                            []
                          }
                          setEmploymentIncomeInfos={(newEmploymentInfo) =>
                            updateApplicationDetails(
                              "employment_income_info",
                              newEmploymentInfo
                            )
                          }
                        />
                      )}
                      {currentStep === 6 && (
                        <ReferencesAndContactsSection
                          personalReferences={
                            tenantRentalApplicationDetails.personal_references ||
                            []
                          }
                          setPersonalReferences={(newReferences) =>
                            setTenantRentalApplicationDetails((prev) => ({
                              ...prev,
                              personal_references: newReferences,
                            }))
                          }
                          emergencyContacts={
                            tenantRentalApplicationDetails.emergency_contacts ||
                            []
                          }
                          setEmergencyContacts={(newContacts) =>
                            setTenantRentalApplicationDetails((prev) => ({
                              ...prev,
                              emergency_contacts: newContacts,
                            }))
                          }
                        />
                      )}
                      {currentStep === 7 && (
                        <DocumentUploadSection
                          attachments={
                            tenantRentalApplicationDetails.attachments || []
                          }
                          attachmentUploads={
                            tenantRentalApplicationDetails.attachment_uploads ||
                            []
                          }
                          uploadBtnText="Upload Documents"
                          handleConfirmDocuments={handleConfirmDocuments} // Add this line
                          handleDeleteAttachment={handleDeleteAttachment}
                        />
                      )}
                      {currentStep === 8 && (
                        <FinalStep
                          tenantRentalApplicationDetails={
                            tenantRentalApplicationDetails
                          }
                          onSubmit={handleSubmit}
                          goToSection={goToSection}
                        />
                      )}
                      <div className="d-flex justify-content-between mt-4">
                        {currentStep > 1 && (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                        )}
                        {currentStep < totalSteps && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={nextStep}
                          >
                            {currentStep === 7 ? "Review Application" : "Next"}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Application Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>I hereby agree that:</p>
          <ul>
            <li>
              All information provided in this application is valid and true.
            </li>
            <li>
              The submitted data cannot be retracted from the landlord once
              submitted.
            </li>
            <li>The information provided will be verified.</li>
          </ul>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Signature (Full Name):</Form.Label>
              <Form.Control
                type="text"
                value={signature}
                onChange={handleSignatureChange}
                placeholder={fullName}
                isInvalid={!!signatureError}
              />
              <Form.Control.Feedback type="invalid">
                {signatureError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2">
              <Row className="align-items-center justify-content-end">
                <Col xs="auto">
                  <Form.Check
                    type="checkbox"
                    id="agreement-checkbox"
                    checked={isAgreed}
                    onChange={handleAgreementChange}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="agreement-checkbox" className="mb-0">
                    I agree to the terms stated above
                  </Form.Label>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleModalSubmit}
            disabled={!isAgreed || signature.trim() === ""}
          >
            Accept and Submit Application
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default PrimaryApplicationForm;
