import { Component } from "react";
import { logout } from "../../../services/authentication/tenantAuth";
import { toast } from "react-toastify";

class LogOut extends Component {
  async componentDidMount() {
    // remove token from local storage and wait for it to complete
    const logoutSuccessful = await logout();

    if (logoutSuccessful) {
      // redirect user to home page (force refresh)
      window.location = "/";
    } else {
      // show an error toast
      toast.error("Logout failed");
    }
  }

  render() {
    return null;
  }
}

export default LogOut;
