//TODO next: look in calendly for the additional person email that needs to be on the form
//Need the link code feature to link co-occupants
//get the mvp form out for the prescreening and main form tonight
//put a contact us form for them to clikc to go to gurenter if they are having problems with the form
//yofo if the tenant passed presceeening make the schedule button available for
// them to book and send them an email that hey can book at the calendy link
import React from 'react';

const Input = ({
  name,
  label,
  className = 'form-control',
  placeholder,
  labelClass,
  isFocused,
  value,
  onChange,
  type,
  error,
  isDisabled = false,
}) => {
  const handleChange = (event) => {
    if (type === 'date') {
      onChange(event.target.value);
    } else {
      onChange(event);
    }
  };

  const handleClick = (event) => {
    if (type === 'number' && event.target.value == '0') {
      event.target.value = '';
    }
  };

  return (
    <div className='form-group'>
      <label className={labelClass} htmlFor={name}>
        {label}
      </label>

      <input
        autoFocus={isFocused}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        id={name}
        name={name}
        type={type}
        className={className}
        disabled={isDisabled}
      />

      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Input;
