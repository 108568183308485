//EmergencyContactForm

import React from "react";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import SelectInputLabel from "../../../reusable/form/selectinputlabel";

const PersonalReferenceForm = ({ personalReferences = [], setPersonalReferences }) => {
  const relationshipTypes = [
    "Colleague/Co-Worker", "Coach", "High Ranking Officer", "Justice of the Peace",
    "Mentor", "Notary Public", "Pastor or Religious Leader", "Previous Landlord",
    "Professional Acquaintance", "Supervisor/Manager", "Teacher/Professor", "Volunteer Supervisor"
  ];

  const handleChange = (index, field, value) => {
    const updatedReferences = personalReferences.map((reference, i) => 
      i === index ? { ...reference, [field]: value } : reference
    );
    setPersonalReferences(updatedReferences);
  };

  const handleAdd = () => {
    setPersonalReferences([...personalReferences, {
      name: "",
      phone_number: "",
      email: "",
      relationship: "",
    }]);
  };

  const handleRemove = (index) => {
    const updatedReferences = personalReferences.filter((_, i) => i !== index);
    setPersonalReferences(updatedReferences);
  };

  return (
    <div>
      {personalReferences.map((reference, index) => (
        <div key={index} className="row gy-4 mb-4">
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="name"
              value={reference?.name || ""}
              label="Reference Name"
              onChange={(e) => handleChange(index, "name", e.target.value)}
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="phone_number"
              value={reference.phone_number}
              label="Phone Number"
              onChange={(e) => handleChange(index, "phone_number", e.target.value)}
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="email"
              value={reference.email}
              label="Email"
              onChange={(e) => handleChange(index, "email", e.target.value)}
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <SelectInputLabel
              name="relationship"
              value={reference.relationship}
              label="Relationship"
              onChange={(e) => handleChange(index, "relationship", e.target.value)}
            >
              <option value="">Select...</option>
              {relationshipTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </SelectInputLabel>
          </div>
          <div className="col-xxl-1 col-md-1">
            <button
              type="button"
              className="btn btn-danger btn-icon waves-effect waves-light"
              onClick={() => handleRemove(index)}
            >
              <i className="ri-delete-bin-5-line"></i>
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-success w-lg waves-effect waves-light"
        onClick={handleAdd}
      >
        <i className="ri-add-fill"></i> Add Personal Reference
      </button>
    </div>
  );
};

export default PersonalReferenceForm;
