import React, { useState } from "react";
import { formatDateValue } from "../../../utils/dateUtils";
import { Accordion, Card, Button } from "react-bootstrap";

const FinalStep = ({
  tenantRentalApplicationDetails,
  onSubmit,
  goToSection,
}) => {
  const [activeKey, setActiveKey] = useState("0");

  const {
    first_name,
    last_name,
    email_address,
    phone_number,
    current_addresses,
    previous_addresses,
    co_applicant_connections,
    employment_income_info,
    personal_references,
    emergency_contacts,
    attachments,
  } = tenantRentalApplicationDetails;

  const renderAddress = (address) => (
    <div>
      <p className="mb-1">{address.address}</p>
      <p className="mb-1">{`${address.city}, ${address.state} ${address.zip_code}`}</p>
      <p className="mb-1">{`Landlord: ${address.landlord_name}`}</p>
      <p className="mb-1">{`Landlord Phone: ${address.landlord_phone_number}`}</p>
      <p className="mb-1">{`Monthly Rent: $${address.monthly_rent}`}</p>
      <p className="mb-1">{`Date In: ${formatDateValue(
        address.date_of_residence_start
      )}`}</p>
      {address.date_of_residence_end && (
        <p className="mb-1">{`Date Out: ${formatDateValue(
          address.date_of_residence_end
        )}`}</p>
      )}
    </div>
  );

  const sections = [
    {
      title: "Personal Information",
      content: (
        <>
          <p className="mb-1">
            <strong>Name:</strong> {first_name} {last_name}
          </p>
          <p className="mb-1">
            <strong>Email:</strong> {email_address}
          </p>
          <p className="mb-1">
            <strong>Phone:</strong> {phone_number}
          </p>
        </>
      ),
      sectionIndex: 1,
    },
    {
      title: "Current Address",
      content: current_addresses[0] && renderAddress(current_addresses[0]),
      sectionIndex: 2,
    },
    {
      title: "Previous Address",
      content: previous_addresses[0] && renderAddress(previous_addresses[0]),
      sectionIndex: 3,
    },
    {
      title: "Other Occupants",
      content: (
        <>
          {co_applicant_connections.map((occupant, index) => (
            <div key={index} className="mb-3">
              <p className="mb-1">
                <strong>Name:</strong> {occupant.first_name}{" "}
                {occupant.last_name}
              </p>
              <p className="mb-1">
                <strong>Relationship:</strong> {occupant.relationship}
              </p>
              <p className="mb-1">
                <strong>Date of Birth:</strong>{" "}
                {formatDateValue(occupant.date_of_birth)}
              </p>
            </div>
          ))}
        </>
      ),
      sectionIndex: 4,
    },
    {
      title: "Employment and Income",
      content: (
        <>
          {employment_income_info.map((employment, index) => (
            <div key={index} className="mb-3">
              <p className="mb-1">
                <strong>Employer:</strong> {employment.employer_name}
              </p>
              <p className="mb-1">
                <strong>Start Date:</strong>{" "}
                {formatDateValue(employment.employer_start_date)}
              </p>
              {!employment.is_current_employer && (
                <p className="mb-1">
                  <strong>End Date:</strong>{" "}
                  {formatDateValue(employment.employer_end_date)}
                </p>
              )}
              <p className="mb-1">
                <strong>Annual Income:</strong> ${employment.annual_income}
              </p>
            </div>
          ))}
        </>
      ),
      sectionIndex: 5,
    },
    {
      title: "References and Contacts",
      content: (
        <>
          <h6>Personal References</h6>
          {personal_references.map((reference, index) => (
            <div key={index} className="mb-2">
              <p className="mb-1">
                <strong>Name:</strong> {reference.name}
              </p>
              <p className="mb-1">
                <strong>Phone:</strong> {reference.phone_number}
              </p>
            </div>
          ))}
          <h6 className="mt-3">Emergency Contacts</h6>
          {emergency_contacts.map((contact, index) => (
            <div key={index} className="mb-2">
              <p className="mb-1">
                <strong>Name:</strong> {contact.name}
              </p>
              <p className="mb-1">
                <strong>Phone:</strong> {contact.phone_number}
              </p>
              <p className="mb-1">
                <strong>Relationship:</strong> {contact.relationship}
              </p>
            </div>
          ))}
        </>
      ),
      sectionIndex: 6,
    },
    {
      title: "Uploaded Documents",
      content: (
        <ul className="list-group">
          {attachments.map((attachment, index) => (
            <li key={index} className="list-group-item">
              <i className="ri-file-text-line me-2"></i>
              {attachment.file_name}
            </li>
          ))}
        </ul>
      ),
      sectionIndex: 7,
    },
  ];

  return (
    <div className="container-fluid">
      <h2 className="mb-4">Review Your Application</h2>

      <Accordion activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        {sections.map((section, index) => (
          <Card key={index}>
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{section.title}</Accordion.Header>
              <Accordion.Body>
                {section.content}
                <Button
                  variant="primary"
                  className="mt-2"
                  onClick={() => goToSection(section.sectionIndex)}
                >
                  Edit this section
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export default FinalStep;
