
export class Tenant{
    constructor(tenant_id, first_name, middle_name, last_name, email_address, login_code, expires_at, stripe_customer_id, phone_number, created_at, updated_at, is_deleted, is_active, is_verified, campaign_id, profile_img_url){
        this.tenant_id = tenant_id;
        this.first_name = first_name;
        this.middle_name = middle_name;
        this.last_name = last_name;
        this.email_address = email_address;
        this.login_code = login_code;
        this.expires_at = expires_at;
        this.stripe_customer_id = stripe_customer_id;
        this.phone_number = phone_number;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.is_deleted = is_deleted;
        this.is_active = is_active;
        this.is_verified = is_verified;
        this.campaign_id = campaign_id;
        this.profile_img_url = profile_img_url;
    }
}