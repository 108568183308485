import React, { useEffect } from "react";
import TextInputLabel from "../../../reusable/form/textinputlabel";

const PersonalInformationSection = ({
  tenantRentalApplicationDetails,
  setTenantRentalApplicationDetails,
  tenantProfile,
  errors,
}) => {
  useEffect(() => {
    if (tenantProfile) {
      setTenantRentalApplicationDetails((prev) => ({
        ...prev,
        first_name: prev.first_name || tenantProfile.first_name || "",
        last_name: prev.last_name || tenantProfile.last_name || "",
        email_address: prev.email_address || tenantProfile.email_address || "",
        phone_number: prev.phone_number || tenantProfile.phone_number || "",
      }));
    }
  }, [tenantProfile, setTenantRentalApplicationDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenantRentalApplicationDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <section>
      <h5 className="mb-3">Personal Information</h5>
      <div className="row gy-4">
        <div className="col-md-6">
          <TextInputLabel
            name="first_name"
            value={tenantRentalApplicationDetails?.first_name || ""}
            label="First Name"
            error={errors?.first_name}
            onChange={handleChange}
            disabled={true}
            readOnly={true}
          />
        </div>
        <div className="col-md-6">
          <TextInputLabel
            name="last_name"
            value={tenantRentalApplicationDetails?.last_name || ""}
            label="Last Name"
            error={errors?.last_name}
            onChange={handleChange}
            disabled={true}
            readOnly={true}
          />
        </div>
        <div className="col-md-6">
          <TextInputLabel
            name="email_address"
            value={tenantRentalApplicationDetails?.email_address || ""}
            label="Email Address"
            error={errors?.email_address}
            onChange={handleChange}
            disabled={true}
            readOnly={true}
          />
        </div>
        <div className="col-md-6">
          <TextInputLabel
            name="phone_number"
            value={tenantRentalApplicationDetails?.phone_number || ""}
            label="Phone Number"
            error={errors?.phone_number}
            onChange={handleChange}
          />
        </div>
      </div>
    </section>
  );
};

export default PersonalInformationSection;
