import React from "react";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import Input from "../../../reusable/form/Input";

const EmploymentAndIncomeSection = ({
  employmentIncomeInfos,
  setEmploymentIncomeInfos,
}) => {
  const handleChange = (index, field, value) => {
    const updatedEmploymentInfo = employmentIncomeInfos.map((info, i) =>
      i === index ? { ...info, [field]: value } : info
    );
    setEmploymentIncomeInfos(updatedEmploymentInfo);
  };

  const handleAdd = () => {
    const currentDate = new Date();
    setEmploymentIncomeInfos([
      ...employmentIncomeInfos,
      {
        employer_name: "",
        employer_address: "",
        employer_city: "",
        employer_state: "",
        employer_zip_code: "",
        employer_phone_number: "",
        employer_email: "",
        employer_start_date: currentDate,
        employer_end_date: currentDate,
        annual_income: "",
        is_current_employer: false,
      },
    ]);
  };

  const handleRemove = (index) => {
    const updatedEmploymentInfo = employmentIncomeInfos.filter(
      (_, i) => i !== index
    );
    setEmploymentIncomeInfos(updatedEmploymentInfo);
  };

  const ensureValue = (value) => {
    return value === undefined || value === null ? "" : value;
  };

  const formatDateValue = (dateValue) => {
    if (!dateValue) return "";
    if (typeof dateValue === "string") {
      return dateValue.split("T")[0];
    }
    if (dateValue instanceof Date) {
      return dateValue.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <div>
      {employmentIncomeInfos.map((employment, index) => (
        <div key={index} className="row gy-4 mb-4">
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="employer_name"
              value={employment?.employer_name || ""}
              label="Employer Name"
              onChange={(e) =>
                handleChange(index, "employer_name", e.target.value)
              }
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="employer_address"
              value={employment.employer_address}
              label="Employer Address"
              onChange={(e) =>
                handleChange(index, "employer_address", e.target.value)
              }
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="employer_phone_number"
              value={employment.employer_phone_number}
              label="Employer Phone"
              onChange={(e) =>
                handleChange(index, "employer_phone_number", e.target.value)
              }
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <Input
              showIcon
              name="employer_start_date"
              value={formatDateValue(employment.employer_start_date)}
              label="Start Date"
              onChange={(date) =>
                handleChange(index, "employer_start_date", date)
              }
              type="date"
            />
          </div>
          <div className="col-xxl-3 col-md-6">
            <div className="mb-3">
              <label
                className="form-label d-block mb-2"
                htmlFor={`currentEmployer${index}`}
              >
                Current Employer
              </label>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`currentEmployer${index}`}
                  checked={employment.is_current_employer}
                  onChange={(e) =>
                    handleChange(index, "is_current_employer", e.target.checked)
                  }
                />
              </div>
            </div>
          </div>
          {!employment.is_current_employer && (
            <div className="col-xxl-3 col-md-6">
              <Input
                showIcon
                name="employer_end_date"
                value={formatDateValue(employment.employer_end_date)}
                label="End Date"
                onChange={(date) =>
                  handleChange(index, "employer_end_date", date)
                }
                type="date"
              />
            </div>
          )}
          <div className="col-xxl-3 col-md-6">
            <TextInputLabel
              name="annual_income"
              value={ensureValue(employment.annual_income)}
              label="Annual Income"
              onChange={(e) =>
                handleChange(index, "annual_income", e.target.value)
              }
              type="number"
            />
          </div>
          <div className="col-xxl-1 col-md-6 d-flex align-items-end">
            <div className="d-flex gap-2">
              <button
                type="button"
                className="btn btn-danger btn-icon waves-effect waves-light"
                onClick={() => handleRemove(index)}
              >
                <i className="ri-delete-bin-5-line"></i>
              </button>
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-success w-lg waves-effect waves-light"
        onClick={handleAdd}
      >
        <i className="ri-add-fill"></i> Add Employment
      </button>
    </div>
  );
};

export default EmploymentAndIncomeSection;
