import http from "../general/httpService";
import config from "../../constants/config.json";

// /sys_tenant_job_category/get_tenant_job_categories
export async function getTenantJobCategories() {
  const fullApiEndpoint = config.apiEndpoint + "/sys_tenant_job_category/get_tenant_job_categories";
  const {data : response} = await http.get(fullApiEndpoint);
  return response;
}

// /sys_tenant_job_category/get_tenant_job_category/{job_category_id}
export async function getTenantJobCategoryById(jobCategoryId) {
  const fullApiEndpoint = config.apiEndpoint + `/sys_tenant_job_category/get_tenant_job_category/${jobCategoryId}`;
  const {data : response} = await http.get(fullApiEndpoint);
  return response;
}


